const folderPaths = Object.freeze({
  pdfs: "pdfs",
});

const currencies = Object.freeze({
  mxn: { value: "MXN" },
  usd: { value: "USD" },
});

const userTypes = Object.freeze({
  admin: { name: "Administrador", value: "admin" },
  support: { name: "Soporte", value: "support" },
  manager: { name: "Gerente", value: "manager" },
});

const titlesNavbar = {
  dashboard: "Resumen",
  pdfs: "PDFs",
  history: "Historial",
  users: "Usuarios",
  organizations: "Organizaciones",
};

const traslatedPdfsStatus = Object.freeze({
  created: { name: "Creado", value: "created", color: "blue" },
  in_progress: { name: "En progreso", value: "in_progress", color: "yellow" },
  error: { name: "Error", value: "error", color: "red" },
  completed: { name: "Completado", value: "completed", color: "green" },
});

const templateTypes = Object.freeze({
  tokyo_steel: "TOKYO STEEL MFG.CO., LTD.",
  //jsw: "JSW STEEL COATED PRODUCTS LIMITED",
  dongkuk: "DONGKUK CM",
  posco: "POSCO Steeleon Co.",
  //maruichi: "MARUICHI SUN STEEL JOINT STOCK CO.",
  //acciaieried: "Acciaieried’ItaliaS.p.AinA.S.",
  hyundai_jungbong: "HYUNDAI Steel (Jungbong)",
  hyundai_bukbusaneopno: "HYUNDAI Steel (Bukbusaneopno)",
});

const staticPassword = "123456";

export {
  folderPaths,
  currencies,
  userTypes,
  titlesNavbar,
  traslatedPdfsStatus,
  templateTypes,
  staticPassword,
};
