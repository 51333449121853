import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { Image, Typography } from "antd";
import {
  UserOutlined,
  FileTextOutlined,
  FilePdfOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { breakpoints } from "theme/media";
import { Menu, Sider } from "./elements";
import { useUser } from "providers/user";
import { userTypes } from "utils/constants";

const { Item } = Menu;
const { Text } = Typography;

const Sidebar = ({ history, collapsed, onCollapse }) => {
  const { user } = useUser();
  const [isLg, toggleLg] = useState(window.innerWidth > breakpoints.lg);

  const updateWidth = () => {
    toggleLg(window.innerWidth > breakpoints.lg);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);

    return () => window.removeEventListener("resize", updateWidth);
  }, []);
  let routes = (currentPath) => (
    <>
      <Item
        icon={
          <FilePdfOutlined
            style={{ color: currentPath === "/pdfs" ? "black" : "white" }}
          />
        }
        key="/pdfs"
      >
        <Link to="/pdfs">
          <Text
            style={{ color: currentPath === "/pdfs" ? "black" : "white" }}
            strong
          >
            PDFs
          </Text>
        </Link>
      </Item>
      <Item
        icon={
          <FileTextOutlined
            style={{ color: currentPath === "/history" ? "black" : "white" }}
          />
        }
        key="/history"
      >
        <Link to="/history">
          <Text
            style={{ color: currentPath === "/history" ? "black" : "white" }}
            strong
          >
            Historial
          </Text>
        </Link>
      </Item>
      <Item
        icon={
          <UserOutlined
            style={{ color: currentPath === "/users" ? "black" : "white" }}
          />
        }
        key="/users"
      >
        <Link to="/users">
          <Text
            style={{ color: currentPath === "/users" ? "black" : "white" }}
            strong
          >
            Usuarios
          </Text>
        </Link>
      </Item>
    </>
  );

  let routesAdmin = (currentPath) => (
    <>
      <Item
        icon={
          <TeamOutlined
            style={{
              color: currentPath === "/organizations" ? "black" : "white",
            }}
          />
        }
        key="/organizations"
      >
        <Link to="/organizations">
          <Text
            style={{
              color: currentPath === "/organizations" ? "black" : "white",
            }}
            strong
          >
            Organizaciones
          </Text>
        </Link>
      </Item>
      <Item
        icon={
          <FileTextOutlined
            style={{ color: currentPath === "/history" ? "black" : "white" }}
          />
        }
        key="/history"
      >
        <Link to="/history">
          <Text
            style={{ color: currentPath === "/history" ? "black" : "white" }}
            strong
          >
            Historial
          </Text>
        </Link>
      </Item>
      <Item
        icon={
          <UserOutlined
            style={{ color: currentPath === "/users" ? "black" : "white" }}
          />
        }
        key="/users"
      >
        <Link to="/users">
          <Text
            style={{ color: currentPath === "/users" ? "black" : "white" }}
            strong
          >
            Usuarios
          </Text>
        </Link>
      </Item>
    </>
  );

  return (
    <Sider
      breakpoint="lg"
      collapsedWidth={isLg ? "80" : "0"}
      theme="light"
      collapsed={collapsed}
      collapsible
      onCollapse={onCollapse}
    >
      <Image
        style={{
          margin: "10px auto",
          display: "block",
          paddingLeft: 40,
          paddingRight: 40,
        }}
        height={35}
        src={user?.organization?.logo}
        alt=""
      />
      <Menu
        theme="light"
        style={{ marginTop: 30, backgroundColor: "#001528" }}
        defaultSelectedKeys={history.location.pathname.toLowerCase()}
        selectedKeys={history.location.pathname.toLowerCase()}
        mode="inline"
      >
        {user?.type === userTypes.admin.value
          ? routesAdmin(history.location.pathname.toLowerCase())
          : routes(history.location.pathname.toLowerCase())}
      </Menu>
    </Sider>
  );
};

export default withRouter(Sidebar);
