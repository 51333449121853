import React from "react";
import { withRouter } from "react-router-dom";
import MainLayout from "layouts/main";
import AuthLayout from "layouts/auth";
import Authentication from "views/private/routes/authentication";
import CRM from "views/private/routes/crm";
import { useUser } from "providers/user";
import { Spin } from "antd";
import { BeatingContainer } from "./elements";

const Private = () => {
  const { user, userLoading } = useUser();

  if (!user) {
    return (
      <AuthLayout>
        <Authentication />
      </AuthLayout>
    );
  }

  if (userLoading)
    return (
      <BeatingContainer>
        <Spin size="large" />
      </BeatingContainer>
    );

  return (
    <MainLayout>
      <CRM />
    </MainLayout>
  );
};

export default withRouter(Private);
