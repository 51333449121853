import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter as Router } from "react-router-dom";
import { ConfigProvider } from "antd";
import esES from "antd/lib/locale/es_ES";
import reportWebVitals from "./reportWebVitals";
import { UserProvider } from "./providers/user";
import { ThemeProvider } from "styled-components";
import App from "./App";
import theme from "theme";
import "moment/locale/es";
import { ApolloProvider } from "@apollo/client";
import client from "graph-ql";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ApolloProvider client={client}>
    <ThemeProvider theme={theme}>
      <UserProvider>
        <Router basename="/">
          <ConfigProvider
            locale={esES}
            theme={{
              token: {
                colorPrimary: theme.colors.primary,
                colorSecondary: theme.colors.secondary,
                colorIcon: theme.colors.primary,
              },
            }}
          >
            <App />
          </ConfigProvider>
        </Router>
      </UserProvider>
    </ThemeProvider>
  </ApolloProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
