import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import Loadable from "react-loadable";
import { useUser } from "providers/user";

const Dashboard = Loadable({
  loader: () => import("./routes/dashboard"),
  loading: TopBarProgress,
});

const PDFs = Loadable({
  loader: () => import("./routes/pdfs"),
  loading: TopBarProgress,
});

const History = Loadable({
  loader: () => import("./routes/history"),
  loading: TopBarProgress,
});

const Users = Loadable({
  loader: () => import("./routes/users"),
  loading: TopBarProgress,
});

const Organizations = Loadable({
  loader: () => import("./admin/organizations"),
  loading: TopBarProgress,
});

const Dash = () => {
  const { user } = useUser();

  if (user?.type === "admin") {
    return (
      <Switch>
        <Route path="/organizations">
          <Organizations />
        </Route>
        <Route path="/history">
          <History />
        </Route>
        <Route path="/users">
          <Users />
        </Route>
        <Redirect to="/organizations" />
      </Switch>
    );
  } else if (user?.type === "manager" || user?.type === "support") {
    return (
      <Switch>
        <Route path="/dashboard">
          <Dashboard />
        </Route>
        <Route path="/pdfs">
          <PDFs />
        </Route>
        <Route path="/users">
          <Users />
        </Route>
        <Route path="/history">
          <History />
        </Route>
        <Redirect to="/pdfs" />
      </Switch>
    );
  } else {
    return <Switch></Switch>;
  }
};

export default Dash;
