import { gql } from "@apollo/client";

const GET_USER = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      email
      type
      passwordChangeRequired
      organization {
        id
        logo
        name
        active
      }
    }
  }
`;

export { GET_USER };
