import React from "react";
import { Switch, Redirect } from "react-router-dom";

const Public = () => {
  return (
    <Switch>
      <Redirect to="/login" />
    </Switch>
  );
};

export default Public;
