import urljoin from "url-join";

const env = {
  development: process.env.NODE_ENV === "development",
  production: process.env.NODE_ENV === "production",
};

const backend = {
  AUTH_URI: process.env.REACT_APP_SERVER_URI,
  HTTP_URI: urljoin(process.env.REACT_APP_SERVER_URI ?? "", "graphql"),
  apiRoute: process.env.REACT_APP_API_ROUTE,
  GRAPHQL_URI: urljoin(process.env.REACT_APP_GRAPHQL_URI ?? "", "graphql"),
};

const publicNamespace = process.env.REACT_APP_PUBLIC_NAMESPACE;

export { env, backend, publicNamespace };
