import styled, { keyframes } from "styled-components";

const beatingKeyFrame = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
`;

const BeatingContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: 3s ${beatingKeyFrame} infinite;
`;

export { BeatingContainer };
