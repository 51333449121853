import { gql } from "@apollo/client";

const UPDATE_PASSWORD = gql`
  mutation updatePassword(
    $id: ID
    $currentPassword: String
    $newPassword: String
  ) {
    updatePassword(
      id: $id
      currentPassword: $currentPassword
      newPassword: $newPassword
    )
  }
`;

export { UPDATE_PASSWORD };
