import styled, { keyframes } from "styled-components";

const HeaderKeyFrame = keyframes`
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
`;

const AuthWrapper = styled.div``;

const DisplayWrapper = styled.div`
  width: 100%;
  background: linear-gradient(to right, rgba(25, 113, 159, 0.95) 0%, rgba(25, 113, 159, 0.85) 50%, rgba(25, 113, 159, 0.65) 100%);
  animation: ${HeaderKeyFrame} 20s ease infinite;
  background-size: 200% 100%;
  }
`;

// 24571B
export { AuthWrapper, DisplayWrapper };
