import styled from "styled-components";
import { Layout, Button, Menu as CommonMenu } from "antd";

const { Header } = Layout;

const NavbarContainer = styled(Header)`
  background: none !important;
  padding: 0px !important;
`;

const Menu = styled(CommonMenu)`
  align-items: center;
  height: fit-content;
  display: flex;
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 15px;
`;

const ProfileButton = styled(Button)`
  height: 100%;
  display: flex;
  align-items: center;
`;

export { NavbarContainer, Menu, ProfileButton, NameContainer };
