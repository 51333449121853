import React, { useState } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { Dropdown, Typography, Button } from "antd";
import shortid from "shortid";
import { Link } from "react-router-dom";
import {
  DownOutlined,
  LogoutOutlined,
  MailOutlined,
  KeyOutlined,
} from "@ant-design/icons";
import {
  NavbarContainer,
  Menu,
  ProfileButton,
  NameContainer,
} from "./elements";
import { useUser } from "providers/user";
import { titlesNavbar } from "utils/constants";
import UpdatePasswordModal from "views/private/routes/crm/routes/update-password";
import { PageHeader } from "@ant-design/pro-components";

const { Text } = Typography;

function NavBar() {
  const { user, logout, userRefetch } = useUser();
  const { pathname } = useLocation();
  const [updatePasswordVisible, setUpdatePasswordVisible] = useState(false);

  const handleLogout = async () => {
    await logout();
  };

  const format = (pathname) => {
    const titleKey = pathname.substring(pathname.lastIndexOf("/") + 1);
    const translatedTitle = titlesNavbar[titleKey];
    if (translatedTitle) {
      return translatedTitle;
    } else {
      return (
        titleKey.replace(/-/g, " ").charAt(0).toUpperCase() + titleKey.slice(1)
      );
    }
  };

  return (
    <NavbarContainer>
      <Menu
        mode="horizontal"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <PageHeader
          style={{ marginRight: "auto", padding: "0px 20px" }}
          title={
            user?.organization?.name
              ? `${format(pathname)} - ${user.organization.name}`
              : format(pathname)
          }
        />
        <Link to="/">
          <Button
            onClick={() => {
              const today = new Date();
              const dateString = today.toLocaleDateString("es-CA");
              const mailtoLink = `mailto:elujan@nips.com.mx?subject=${dateString} - Soporte&body=Buenas, me gustaría recibir apoyo con el siguiente tema: `;
              window.open(mailtoLink, "_blank");
            }}
            type="primary"
            icon={<MailOutlined />}
          >
            Soporte
          </Button>
        </Link>
        <Dropdown
          trigger={["click"]}
          overlay={
            <Menu mode="vertical">
              <Menu.ItemGroup title="Cuenta">
                <Menu.Item
                  key={shortid.generate()}
                  onClick={() => setUpdatePasswordVisible(true)}
                  icon={<KeyOutlined />}
                >
                  Cambiar Contraseña
                </Menu.Item>
              </Menu.ItemGroup>
              <Menu.ItemGroup title="Sesión">
                <Menu.Item
                  key={shortid.generate()}
                  onClick={() => handleLogout()}
                  icon={<LogoutOutlined style={{ color: "red" }} />}
                >
                  <Link
                    to={`/login?org_id=${user?.organization?.id}`}
                    style={{ color: "red" }}
                  >
                    Salir
                  </Link>
                </Menu.Item>
              </Menu.ItemGroup>
            </Menu>
          }
        >
          <ProfileButton type="text">
            <NameContainer>
              <Text style={{ fontSize: 12, color: "#4B89E3" }} type="secondary">
                Hola,
              </Text>
              <Text style={{ marginTop: -5, color: "#4B89E3" }} strong>
                {user?.email}
              </Text>
            </NameContainer>
            <DownOutlined />
          </ProfileButton>
        </Dropdown>
      </Menu>
      <UpdatePasswordModal
        visible={updatePasswordVisible}
        setVisible={setUpdatePasswordVisible}
        user={user}
        userRefetch={userRefetch}
      />
    </NavbarContainer>
  );
}

export default withRouter(NavBar);
